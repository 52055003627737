import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import QRCode from 'qrcode.react';
import { Button } from '@material-ui/core';

export default ({ building, tourPoint }) => {
  const { title, link } = tourPoint;

  return (
    <div>
      <Typography variant="h4">{building.title}</Typography>
      <Typography variant="h5">{title}</Typography>
      
      <Link href={link} target="_blank">{link}</Link>

      <Box pt={2} id="svg-container">
        <QRCode 
          renderAs='svg'
          value={link} 
          size={300}
          />
      </Box>

      <Box pt={2}>
        <Button onClick={() => download()}>Download SVG</Button>
      </Box>
    </div>
  );
}

const download = () => {
  const svg = document
    .getElementById('svg-container')
    .innerHTML
    .replace('<svg ', "<?xml version='1.0' encoding='UTF-8'?>\n<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' ");

  const element = document.createElement("a");
  const file = new Blob([svg], { type: 'text/svg' });
  
  element.href = URL.createObjectURL(file);
  element.download = "ohv-code.svg";
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
  document.body.removeChild(element);
};