import React from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { ApolloProvider } from '@apollo/react-hooks';
import client from './apollo';
import TourPoints from './TourPoints';

export default function App() {
  return (
    <ApolloProvider client={client}>
      <Container>
        <Box pt={4}>
          <Typography variant="h2" gutterBottom>
            Maršrutai
          </Typography>
          <TourPoints />
        </Box>
      </Container>
    </ApolloProvider>
  );
}
