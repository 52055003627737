import React, { useState } from 'react';
import { gql } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Point from './Point';

const QUERY = gql`
  query BuildingsQuery($language: LanguageCodeFilterEnum!) {
    buildings(
      where: { orderby: { field: MENU_ORDER, order: ASC }, language: $language }
    ) {
      nodes {
        id
        title
        tourPoints {
          title
          link
        }
      }
    }
  }
`;

export default () => {
  const { loading, error, data } = useQuery(QUERY, { 
    variables: { language: 'LT' }
  });

  const [current, setCurrent] = useState();
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  const buildings = data.buildings.nodes.filter(({ tourPoints }) => tourPoints.length);

  return (
    <Grid container>
      <Grid item xs={4}>
        {buildings.map((building) => (
          <div key={building.id}>
            <Typography variant="h4">{building.title}</Typography>

            <ol>
              {building.tourPoints.map(tourPoint => (
                <li key={tourPoint.link}>
                  <Link href="#" onClick={() => setCurrent({ building, tourPoint })}>
                    {tourPoint.title}
                  </Link>
                </li>
              ))}
            </ol>
          </div>
        ))}
      </Grid>   
      <Grid item xs={8}>
        {current && <Point tourPoint={current.tourPoint} building={current.building} />}
      </Grid>
    </Grid>
  );
}